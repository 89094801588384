.user-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-item {
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 80%;
  max-width: 600px;
}

.user-image {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 60%; /* Szerokość 80% kontenera */
  height: auto; /* Automatyczna wysokość, zachowująca proporcje */
  z-index: 100;
}
.user-description {
  opacity: 0;
  transform: translateX(-150px) scale(0); /* Początkowa skala na 0 */
  transition: opacity 0.6s ease, transform 0.6s ease, height 0.6s ease,
    width 0.6s ease; /* Dodano transition dla height i width */
  font-size: 1.2rem;
  color: #333;
  background-color: antiquewhite;
  height: auto;
  min-width: 150px;
  padding: 10px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

/* Widoczność opisów po aktywacji */
.user-description.visible {
  opacity: 1;
  transform: translateX(0);
}

@media (max-width: 768px) {
  .user-description {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .user-description {
    font-size: 0.9rem;
  }
}
