html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.scene {
  width: 100vw;
  height: 100vh;
  font-family: sans-serif;
  background-image: linear-gradient(
    180deg,
    hsl(270deg, 100%, 10%) 1%,
    /* ciemny fiolet */ hsl(270deg, 100%, 13%) 17%,
    /* ciemniejszy fiolet */ hsl(270deg, 100%, 16%) 26%,
    /* ciemny fiolet */ hsl(270deg, 100%, 19%) 37%,
    /* fiolet */ hsl(270deg, 100%, 22%) 46%,
    /* jasny fiolet */ hsl(270deg, 100%, 25%) 53%,
    /* jaśniejszy fiolet */ hsl(270deg, 100%, 28%) 59%,
    /* fiolet */ hsl(270deg, 100%, 31%) 65%,
    /* fiolet */ hsl(270deg, 100%, 34%) 70%,
    /* fiolet */ hsl(270deg, 100%, 37%) 74%,
    /* jasny fiolet */ hsl(270deg, 100%, 40%) 78%,
    /* jaśniejszy fiolet */ hsl(270deg, 100%, 43%) 81%,
    /* bardzo jasny fiolet */ hsl(270deg, 100%, 46%) 84%,
    /* prawie biały fiolet */ hsl(270deg, 100%, 49%) 87%,
    /* bardzo jasny fiolet */ hsl(270deg, 100%, 52%) 89%,
    /* prawie biały fiolet */ hsl(270deg, 100%, 55%) 91%,
    /* prawie biały fiolet */ hsl(270deg, 100%, 58%) 93%,
    /* jasny fiolet */ hsl(270deg, 100%, 61%) 95%,
    /* jasny fiolet */ hsl(270deg, 100%, 64%) 96%,
    /* jaśniejszy fiolet */ hsl(270deg, 100%, 67%) 97%,
    /* jasny fiolet */ hsl(270deg, 100%, 70%) 98%,
    /* jaśniejszy fiolet */ hsl(270deg, 100%, 73%) 99%,
    /* jasny fiolet */ hsl(270deg, 100%, 76%) 100% /* bardzo jasny fiolet */
  );
}
.canvas {
  z-index: 1;
  margin: 0;
  padding: 0;
}
